import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  cat: {
    translation: {
      //header
      home: "Inici",
      tools: "Eines",
      settings: "Configuració",
      session: "Tancar sessió",
      access: "Accedeix",

      //Students/Groups
      students: "Alumnes",
      groups: "Grups",

      //AddGroups
      groupAddedMsg: "Grup creat amb èxit",
      editGroupMsg: "Grup editat amb èxit",

      //landing
      mainTitle: "Comença l'experiència ClassBits",
      firstP:
        "Selecciona l'idioma en que vols que estigui la plataforma. Crea la teva classe i comença a gamificar ara mateix! Recorda: com més punts aconsegueixis, a més premis podràs optar!",
      createChibi: "Crea la classe",
      setupClass: "Configura la classe",
      quote:
        "Els jocs i la gamificació condueixen a alts nivells de compromís i motivació de l'alumne. - Karl Kapp -",
      privacyT: "La PRIVACITAT, un dels objectius principals",
      privacyP:
        "Entenem que les dades dels alumnes és informació molt delicada i per això aquesta app ha estat específicament dissenyada per garantir-ne la privacitat.",
      attendanceT: "Dissenyada contra l'absentisme escolar",
      attendanceP:
        "En col·laboració amb l'Ajuntament de Lleida, aquesta aplicació disposa d'un mòdul específic contra l'absentisme escolar. Vine a classe i aconsegueix diamants. Amb aquests diamants podràs comprar capses misterioses que et donaran objectes i personatges únics!",
      explanationT: "App de gestió tutorial configurable",
      explanationP:
        "Al ClassBits es pot afegir, editar i esborrar alumnes, conductes a destacar, a millorar i bescanvis de punts. Tu decideixes quines conductes sumen punts als Chibits i quines en resten... Inclús pots escollir com bescanviar els punts positius per premis a la vida real... o dins del classbits aconseguint una Mascota Bits!",
      positiveReinforcementTitle:
        "Fomenta el reforç de conductes positives i la reconducció de les negatives.",
      positiveReinforcementP:
        "Implementa de forma senzilla i visual les normes de l'aula i què s'espera d'ells. Gràcies al sistema de punts els alumnes veuen de forma ràpida i senzilla com les seves accions comporten bons resultats. A més a més, tot és modificable!",
      toolsT: "Totes les eines que necessites estan al teu abast",
      toolsP:
        "Amb aquest kit d'eines podràs escollir alumnes de forma aleatòria, crear grups de treball de forma automàtica, mesurar el soroll de l'aula, realitzar events divertits, intercanviar punts entre alumnes, llegir frases de reflexió per al dia a dia...",

      //avatar.js
      close: "Tancar",
      custom: "Personalitzat",
      save: "Guardar",

      //AddExchange.js
      addExchangeTitle: "Afegir bescanvi",
      exchange: "Bescanvi",
      points: "Punts",
      addExchangeMsg: "Bescanvi afegit correctament",
      editExchangeMsg: "Bescanvi editat correctament",

      //AddNegative.js
      addNegativeTitle: "Afegir punts negatius",
      pointsToSubstract: "Punts a restar",
      addNegativeMsg: "Negatiu afegit correctament",
      editNegativeMsg: "Negatiu editat correctament",

      //addPet.js
      addPetTo: "Afegir mascota a",
      addPetMsg: "Mascota afegida correctament",
      subtitleAddPet: "Clica les mascotes per veure el seu nom",
      petPoints: "Punts de mascota",
      petPointsCost: "Preu mascota",
      petTransaction: "Transacció de mascota",
      removePet: "Eliminar mascota",

      //addPositive.js
      addPositiveTitle: "Afegir punts positius",
      addPositiveMsg: "Positiu afegit correctament",
      editPositiveMsg: "Positiu editat correctament",

      //addStudents.js
      addStudentTitle: "Afegir alumne",
      studentName: "Nom",
      studentSurname: "Cognom",
      image: "Imatge",
      studentAddedMsg: "Alumne afegit correctament",
      editStudentMsg: "Alumne editat correctament",
      addStudentsWindowWarning:
        "Si us plau, afegeix tots els alumnes sense sortir d'aquesta pantalla.",

      //chooseChibit.js
      createYourChibit: "Crea el teu Chibit",
      chooseChibit: "Escull el teu ChiBit",
      subtitleChooseChibit:
        "Donem un cop d'ull als ChiBits disponibles. Després cada alumne podrà seleccionar el seu. \n Quan hagis guardat tots els/les alumnes de la classe, fes clic al botó vermell de publicar i ja podràs començar a jugar. \n Per qualsevol dubte pots consultar el",
      studentsAmountToBePublished:
        "Quantitat d'alumnes pendents de ser publicats",
      publishClass: "Publicar classe",
      studentsAddedMsg: "Classe publicada correctament",
      studentsToBePublished: "Alumnes afegits",

      //chronometer
      chronoStart: "Comença",
      timeDownStart: "Començar",
      timeDownRemaining: "Temps restant",
      hours: "Hores",
      minutes: "Minuts",
      seconds: "Segons",

      //renderavatars
      addPetPoints: "Afegir punts de mascota i negocis",
      alertPointsAdded:
        "Els propietaris de mascotes i negocis han guanyat punts!",

      //DailySentence.js
      niceSentence: "Genera una frase bonica",

      //Edits
      action: "Acció",
      actions: "Accions",
      negatives: "A millorar",
      positives: "A destacar",
      deleteAll: "Esborrar tot",

      //Groups
      groupsAmount: "Quantitat de grups:",
      createGroups: "Crear grups",
      groupName: "Nom del grup",

      //Login
      register: "Regístrat",
      login: "Inicia sessió",
      googleAccess: "Accedeix amb Google",
      haveAccount: "Tens compte? Inicia sessió",
      notHaveAccount: "No tens compte? Registra't",
      wrongPassword: "La contrasenya és incorrecta, torna-ho a intentar",
      languageTitle: "Selecciona el teu idioma",
      fillForm: "Omple el formulari",
      forgotPassword: "Has olvidat la teva contrasenya? Recupera-la",
      resetPassword: "Reseteja la contrasenya",
      passResetSent: "¡Correu de restabliment enviat!",
      contactThroughMail:
        "Al registrar-te acceptes que t'enviem correus sobre futures actualitzacions rellevants de l'aplicació.",

      //Pergamino
      openPergam: "Obrir pergamí",
      pergam: "Pergamí",
      editPergam: "Pergamí editat",
      addPergam: "Afegir pergamí",
      deletedPergam: "Pergamí esborrat",
      addedPergam: "Pergamí afegit",
      endedPergams:
        "Tots els events s'han mostrat, fes clic de nou per a tornar a començar.",
      editPergams: "Editar pergamins",
      goBack: "Tornar enrere",

      //Daily sentence
      addSentence: "Afegir frase",
      sentence: "Frase",
      editSentence: "Frase editada",
      deletedSentence: "Frase esborrada",
      addedSentence: "Frase afegida",
      endedSentence:
        "Totes les frases s'han mostrat, fes clic de nou per a tornar a començar.",
      editSentences: "Editar frases",

      //Settings
      deleteClassMsg:
        "Estàs segur que vols borrar aquesta classe? ATENCIÓ: Aquesta acció és irreversible!",
      classDeletedMsg: "Classe borrada correctament",
      resetPointsMsg: "Classe resetejada",
      editClassTitle: "Configuració de classe",
      addStudentsBtn: "Afegir alumnes",
      editStudentsBtn: "Editar alumnes",
      pets: "Mascotes",
      pet: "Mascota",
      resetPoints: "Reset punts i negocis",
      deleteClass: "Borrar classe",
      editRules: "Configuració de normes",
      addPositiveBtn: "Afegir positius",
      addNegativeBtn: "Afegir negatius",
      addExchangeBtn: "Afegir bescanvis",
      editPositiveBtn: "Editar positius",
      editNegativeBtn: "Editar negatius",
      editExchangeBtn: "Editar bescanvis",
      addGroupBtn: "Afegir grup",
      editGroupBtn: "Editar grups",
      downloadBackup: "Descarregar còpia de seguretat",
      uploadBackup: "Pujar còpia de seguretat",
      backupTitle: "Còpia de seguretat",
      successBackupMsg: "Còpia de seguretat pujada correctament",
      failedBackupMsg: "Error al penjar la còpia de seguretat",
      noFileBackupMsg: "No s'ha seleccionat cap arxiu de còpia de seguretat",
      uploadBackupBtn: "Pujar backup",
      resetClassMsg:
        "Resetejar punts de la classe i l'historial. ATENCIÓ: Aquesta acció és irreversible!",
      invitedMode: "Mode convidat",
      backToOriginalAccount: "Tornar al meu compte",

      //Pets.js
      activatePetRewards: "Permetre que les mascotes generin 7 punts setmanals",

      //RandomUser
      randomUser: "Usuari aleatori",

      //soundMeter {t("")}
      totalLoudTime: "Has estat en un ambient amb soroll durant",
      seconds: "segons",
      giveMicroAccess: "Has de donar accès al microfon",
      startSoundMeter: "Començar a mesurar",
      stopSoundMeter: "Parar el mesurador",
      allowMicroAccess: "Permet l'ús del microfon",
      soundMeter: "Mesurador de soroll",

      //Tools
      dailySentence: "Frase diària",
      pergamino: "Pergamí del destí",
      groupGenerator: "Generador de grups",
      randomChibit: "ChiBit aleatori",
      chrono: "Cronòmetre",
      countdown: "Compte enrere",
      petsForest: "Bosc de les mascotes",
      exchangePoints: "Intercanvi de punts",
      todoTool: "Tasques de classe",
      seatTool: "Generador seients aleatoris",

      //exchange points tool
      transferPointsTitle: "Transferència de punts",
      receiverPoints: "t'ha fet una transferència de punts",
      senderPoints: "Transferència de punts a",
      selectSender: "Seleccionar remitent",
      selectReceiver: "Seleccionar destinatari",
      btnTransfer: "Realitzar transferència",
      successfulExchangePointsMsg: "Transferència realitzada amb èxit!",
      wrongExchangePointsMsg: "Hi ha hagut un problema, torna-ho a intentar",

      //Footer
      interestLinks: "Enllaços d'interès",
      whoAreWe: "Qui som",
      contact: "Contacte",
      press: "Premsa",
      footerTextUp:
        "Comença avui mateix a gamificar la teva classe. Completament gratis.",
      footerTextDown: "Fet per mestres, per a mestres. Amb molt d'amor 🧡",
      privacyPolicy: "Política de privacitat",
      legalNotice: "Avís legal",
      copyright: "Tots els drets reservats",

      //Team Page
      teamH2: "O millor dit, qui soc.",
      teamText:
        "Em dic Cristian Viaplana i soc professor d'educació primària. Des de fa anys ensenyo i imparteixo, entre altres assignatures, programació i robòtica a nens de primària. \n En els darrers anys, he estat tutor i vaig veure la necessitat de crear una aplicació de gestió tutorial que pogués donar resposta a totes les necessitats del meu alumnat. \n Va ser en aquell moment on va néixer ClassBits. \n Realment, aquest projecte ha anat fent una metamorfosi atès que a mesura que l'usàvem a classe, els nens anaven demanant noves millores i funcionalitats. \n Avui dia, i després de força anys de treball, ClassBits està disponible per a qualsevol professor que vulgui utilitzar-lo. \n Classbits és l'únic programa de gestió tutorial creat a Espanya. A més, té funcionalitats úniques com algunes de les eines, poder escollir entre més de 110 avatars 3D diferents, introducció de conceptes d'economia bàsica (amb els intercanvis, les mascotes que retornen part de la inversió...) entre d'altres. \n D'un professor per a tots els professors. Made in Lleida 🧡",
      teamH1: "Qui som",

      //Contact page
      contactText:
        "Hi ha algun error a l'aplicació? \n Creus que hauríem d'implementar alguna funcionalitat nova? \n Vols contactar-me per a qualsevol tema en general? \n Pots escriure'm a: info@classbits.net",

      //Press page
      pressText:
        "Vols parlar de nosaltres al teu mitjà de comunicació? \n Pots mostrar/adaptar qualsevol apartat de la web per mostrar com funciona ClassBits. A més, aquí teniu recursos per a possibles capçaleres d'articles.",
      downloadables: "Descarregables",
      downloadablesSub: "Fes clic a la imatge per a descarregar-la",
      pressRelease: "Notes de premsa",

      //Todo
      writeToDos:
        "Escriu la tasca que voleu realitzar (no surtis de la pàgina o es perdran les tasques)",

      //emoji gallery
      alertEmojiCopied: "Emoji copiat: ",

      //Account
      myAccount: "El meu compte",
      mail: "Correu",
      profile: "Perfil",
      inviteEmailSuccess: "Compte convidat amb èxit",
      inviteEmailWrong:
        "Alguna cosa no ha funcionat bé, torna-ho a intentar més tard",
      grantedAccess: "Accès permès a la classe de {{professorEmail}}",
      rejectedAccess: "Accès denegat a la classe de {{professorEmail}}",
      titleOtherAccounts: "Accedeix a altres classes",
      titleInviteOthers: "Convida a altres docents a aquesta classe",
      invite: "Convidar",
      saveClassroom: "Guardar classe",
      classroom: "Classe",
      email: "Correu",
      successSavedClassroom: "Classe guardada correctament",
      errorWarning: "Hi ha hagut un error",
      adminPanel: "Panell d'administració",
      accountType: "Tipus de compte:",
      copyReferralLink: "Comparteix el teu enllaç de referit",
      linkCopied: "Enllaç copiat correctament",
      linkCopyError: "Hi ha hagut un error, torna a clicar",
      referralExplanation:
        "Si la gent es registra des del teu enllaç, podràs obtenir funcionalitats exclusives dins la plataforma!",
      totalRefs: "Total referits",

      //FooterTools
      orderAbc: "Ordenar alfabèticament",
      orderOrigin: "Ordre inicial",
      multipleSelect: "Selecció múltiple",
      takeAttendance: "Passar llista",
      attendanceRecorded: "Llista passada",
      havingAttended: "Haver vingut a classe",

      //MultiSelectPopup
      selectAll: "Seleccionar a tots",
      disSelectAll: "Desseleccionar a tots",

      //Seating generator
      descriptionSeatingGenerator:
        "Fes clic a qualsevol lloc de la pantalla per generar seients aleatoris. Un cop hagis acabat, podràs arrossegar-los per a realitzar canvis manuals.",

      //Goal planner
      goal_planner: "Objectius grupals",
      textGoalPlanner:
        "Estableix objectius de classe. Quan els aconsegueixin, guanyaran els punts que s'hagin pactat.",
      addGoalTitle: "Afegir objectiu de classe",
      goalText: "Objectiu de classe",
      goalNumber: "Quantitat de punts a guanyar",
      addGoal: "Afegir objectiu",
      goalAddedMsg: "Objectiu afegit correctament!",
      completed: "Aconseguit",

      //Records
      record: "Historial",
      earnedPoints: "Punts guanyats",
      lostPoints: "Punts perduts",
      all: "Tots",
      description: "Motiu",
      date: "Data",

      //newsletter
      newsletterTitle: "Uneix-te a la nostra newsletter",
      newsletterDescription:
        "Descobreix totes les novetats que anem incorporant a Classbits. Prometem no fer spam. Si et subscrius, aquest quadre no sortirà mai més.",
      subscribe: "Subscriu-te!",

      //thank-you
      thanksForSubscribingTitle: "Gràcies per subscriure't!",
      thanksForSubscribingText:
        "La teva subscripció ha estat confirmada amb èxit! Estem molt emocionats de tenir-te entre nosaltres.",
      afterSubscribingTitle: "I ara què?",
      afterSubscribingText:
        "Preparat per a rebre actualitzacions exclusives, consells útils i les últimes noticies directament al teu mail.",
      acceptTermsAndConditions: "*Accepto les polítiques de privacitat",

      //Student profile
      achievements: "Objectius assolits",
      business: "Negocis",
      collectibles: "Col·leccionables",
      comingsoon: "Pròximament",
      previousStudent: "Anterior",
      nextStudent: "Següent",
      diamonds: "Diamants",
      yes: "Si",
      no: "No",

      //Lootboxes
      lootboxes: "Capses misterioses",
      openLootbox: "Obrir Lootbox",
      lootboxReward: "Premi Lootbox",
      lootboxMsg: "Felicitats! Has aconseguit un",
      legendaryCost: "Cost 15 💎",
      epicCost: "Cost 10 💎",
      commonCost: "Cost 5 💎",
      common: "Comú",
      epic: "Èpica",
      legendary: "Llegendària",
      notEnoughDiamonds: "No tens suficients diamants",
      openedReward: "Felicitats! Has aconseguit  ",
      rewardSkin1: "un personatge llegendari: Flora",
      rewardSkin2: "un personatge llegendari: Poseidó",
      rewardSkin3: "un personatge llegendari: Boreas",
      rewardSkin4: "un personatge llegendari: Hefesto",
      rewardSkin5: "un personatge llegendari: Zeus",
      rewardPet1: "una mascota única: Camaleó",
      rewardPet2: "una mascota única: Floquet de neu",
      rewardPet3: "una mascota mítica: Quimera",
      rewardPet4: "una mascota mítica: Hydra",
      rewardPet5: "una mascota mítica: Minotaur",
      rewardPoints1: "5 punts",
      rewardPoints2: "7 punts",
      rewardPoints3: "10 punts",
      rewardPoints4: "12 punts",
      rewardPoints5: "15 punts",
      businessReward1: "un negoci únic: Botiga d'electrodomèstics",
      businessReward2: "un negoci únic: Botiga de bicis",
      businessReward3: "un negoci únic: Rentador de cotxes",
      businessReward4: "un negoci únic: Autoescola",
      businessReward5: "un negoci únic: Benzinera",
      businessReward6: "un negoci únic: Planta solar",
      businessReward7: "un negoci únic: Mercat",
      businessReward8: "un negoci únic: Fisioterapeuta",
      businessReward9: "un negoci únic: Sabateria",
      businessReward10: "un negoci únic: Hotel",

      //Business
      supermarket: "Supermercat",
      coffeeshop: "Cafeteria",
      bakery: "Fleca",
      fruitstore: "Fruiteria",
      laundry: "Bugaderia",
      carworkshop: "Taller mecànic",
      pharmacy: "Farmàcia",
      gym: "Gimnàs",
      beautysalon: "Centre d'estètica",
      hardwarestore: "Ferreteria",
      clothesstore: "Botiga de roba",
      bookshop: "Llibreria",
      hairsalon: "Perruqueria",
      restaurant: "Restaurant",
      optics: "Òptica",
      florist: "Floristeria",
      factory: "Fàbrica",
      lab: "Laboratori d'invents",
      toyshop: "Joguineria",
      cinema: "Cinema",
      appliance: "Electrodomèstics",
      bike_store: "Botiga de bicis",
      carwash: "Rentador de cotxes",
      driving_school: "Autoescola",
      gas_station: "Benzinera",
      solar_power_plant: "Planta solar",
      marketplace: "Mercat",
      massage: "Fisioterapeuta",
      shoe_store: "Sabateria",
      hotel: "Hotel",
      buyBusiness: "compra un negoci",
      subtitleAddBusiness: "Clica en el negoci per afegir-lo al formulari",
      businessAddedMsg: "Negoci comprat correctament",
      noBusiness: "No tens negocis.",
      noBusinessSelected: "No has seleccionat cap negoci",
      noBusinessToSell: "No pots vendre un negoci que no es teu",
      businessRemovedMsg: "Negoci eliminat correctament",
      removeBusiness: "Vendre negoci",
      businessReturnedPointsMsg: "Venta de negoci",
      businessPoints: "Punts de negoci/s",
      buy: "Comprar",
      sell: "Vendre",
      buySell: "Comprar/Vendre",
      cost: "Preu",
      return_min: "Retorn mínim",
      return_max: "Retorn màxim",
      investment: "Inversió",
      businessPurchased: "Compra de negoci",
      propertyOf: "Propietari/a:",
      information: "Informació",
      next_payment: "Pròxim pagament",

      business_description_1:
        "Pots comprar menjar, joguines i més. Per tenir un supermercat, necessites comprar moltes coses per vendre-les. També necessites pagar les persones que hi treballen. De vegades, guanyes més diners quan molta gent ve a comprar, com en les festes. Però, en algunes èpoques, com quan les persones estan de vacances i no compren tant, el supermercat no guanya tants diners.",
      business_description_2:
        "Fan begudes calentes i pastissos. Per tenir una cafeteria, necessites comprar cafè, llet i altres materials. També necessites pagar les persones que fan el cafè i els pastissos. De vegades, guanyes més diners quan fa fred i les persones volen begudes calentes. Però, a l'estiu, quan fa calor, la gent prefereix begudes fredes, per la qual cosa la cafeteria no guanya tant.",
      business_description_3:
        "Fan pa i pastissos deliciosos. Per tenir una fleca, necessites comprar ingredients com farina i sucre. També necessites pagar els flequers que fan les delícies. A més a més, pots tenir cambrers per servir-les. Guanyes més diners quan les persones volen pa fresc. Però, en dies plujosos, les persones potser no volen sortir a comprar, i la fleca no guanya tant.",
      business_description_4:
        "Venen fruites fresques. Per tenir una fruiteria, necessites comprar fruites i posar-les a la vista. També necessites pagar les persones que hi treballen. De vegades, guanyes més diners quan les persones volen fruites saludables. Però, a l'hivern, algunes fruites no estan disponibles, i la fruiteria pot no guanyar tant.",
      business_description_5:
        "Renten la roba de les persones. Per tenir una bugaderia, necessites comprar rentadores i detergent. La inversió en màquines és considerable. Guanyes més diners quan la gent té molta roba bruta. Però, en alguns dies, com els festius, les persones no porten tanta roba, i la bugaderia no guanya tant.",
      business_description_6:
        "Arreglen cotxes. Per tenir un taller, necessites eines i mecànics que sàpiguen arreglar cotxes. De vegades, guanyes més diners quan els cotxes tenen averies i la gent ve a que els arreglin. Però, en èpoques en què els cotxes no es mouen, el taller no guanya tant.",
      business_description_7:
        "Té medicaments i coses per sentir-se millor. Per tenir una farmàcia, necessites comprar medicaments, llicències i pagar els farmacèutics. Guanyes més diners quan la gent està malalta i necessita medicaments. Però, quan les persones estan saludables, la farmàcia no guanya tant.",
      business_description_8:
        "La gent va a fer exercici per estar sana. Per tenir un gimnàs, necessites comprar màquines i contractar entrenadors. Guanyes més diners quan la gent vol posar-se en forma, com després de les vacances. Però, en alguns mesos, com a l'estiu, les persones poden preferir fer exercici a l'aire lliure, i el gimnàs no guanya tant.",
      business_description_9:
        "En el saló de bellesa, et tallen el cabell i et pentinen. Per tenir un saló, necessites comprar eines i pagar les persones que fan pentinats. De vegades, guanyes més diners quan les persones volen canviar el seu aspecte. Però, en alguns dies tranquils, el saló no guanya tant.",
      business_description_10:
        "A la botiga d'eines, venen coses per arreglar i construir. Per tenir una botiga, necessites comprar eines i pagar les persones que hi treballen. De vegades, guanyes més diners quan les persones tenen projectes de construcció. Però, en temporades tranquiles, la botiga no guanya tant.",
      business_description_11:
        "A la botiga de roba, venen roba nova i bonica. Per tenir una botiga, necessites comprar roba i pagar les persones que hi treballen. De vegades, guanyes més diners quan hi ha temporades de moda. Però, en moments en què la gent no compra tanta roba, la botiga no guanya tant.",
      business_description_12:
        "A la llibreria, venen llibres i contes. Per tenir una llibreria, necessites comprar llibres i pagar les persones que hi treballen. De vegades, guanyes més diners quan les persones volen aprendre coses noves. Però, en moments en què la gent no llegeix tant, la llibreria no guanya tant.",
      business_description_13:
        "En una perruqueria, et tallen el cabell i et pentinen. Per tenir un saló, necessites comprar eines i pagar les persones que fan pentinats. De vegades, guanyes més diners quan les persones volen canviar el seu aspecte. Però, en alguns dies tranquils, el saló no guanya tant.",
      business_description_14:
        "En un restaurant, cuinen menjar deliciós per a tu. Per tenir un restaurant, necessites ingredients, màquines i cuiners. De vegades, guanyes més diners quan la gent vol menjar fora, com a les vacances. Però, en dies en què la gent prefereix cuinar a casa, el restaurant no guanya tant.",
      business_description_15:
        "A l'òptica, ajuden les persones a veure millor amb ulleres. Per tenir una òptica, necessites comprar ulleres i tenir òptics. De vegades, guanyes més diners quan les persones necessiten ulleres noves. Però, quan les persones no necessiten ulleres, l'òptica no guanya tant.",
      business_description_16:
        "A la floristeria, venen flors boniques. Per tenir una floristeria, necessites comprar flors i tenir floristes. De vegades, guanyes més diners quan les persones volen regalar flors o decorar les seves cases. Però, en dies tranquils, la floristeria no guanya tant.",
      business_description_17:
        "Venen joguines divertides. Per tenir una joguineria, necessites comprar joguines i tenir treballadors. De vegades, guanyes més diners quan els nens volen jugar amb joguines noves. Però, en moments en què els nens ja tenen moltes joguines, la joguineria no guanya tant.",
      business_description_18:
        "Al cinema, pots veure pel·lícules emocionants a la gran pantalla. Necessites una sala amb cadires i una gran pantalla. També projectors i pel·lícules per mostrar. Guanyes diners quan les persones compren entrades per veure les pel·lícules. Les pel·lícules noves poden atreure molts espectadors i fer que guanyis molts diners. Però quan no hi ha moltes pel·lícules noves, el cinema pot guanyar menys diners.",
      business_description_19:
        "En una fàbrica, fabriquen moltes coses diferents. Per tenir una fàbrica, necessites màquines i treballadors. De vegades, guanyes molts diners quan la fàbrica produeix moltes coses i les ven. Però en èpoques en què la gent no compra tant, la fàbrica no guanya tant.",
      business_description_20:
        "En un laboratori d'invents, les persones creen coses noves i interessants. Per tenir un laboratori d'invents, necessites científics i materials de recerca. De vegades, guanyes molts diners quan inventen coses genials i les venen. Però en moments en què no tenen moltes idees noves, el laboratori d'invents no guanya tant.",

      //Achievements
      titlebag: "Petit estalviador",
      titlepiggy: "Bon estalviador",
      titlebrokenpiggy: "Gran estalviador",
      titlepeace: "Cuidador",
      titlemoney: "Camí a l'èxit",
      titlegoldenbars: "Inversor tauró",
      titleone_diamond: "Petit assistent",
      titletwo_diamonds: "Bon assistent",
      titlethree_diamonds: "Gran assistent",
      titlelots_of_diamonds: "No te'n perds una!",

      titlechampion: "Devora llibres",
      titlediamond: "Treure molt bones notes",
      titlelike: "Millor amic",
      titlelock: "Valentia",

      achievementbag: "Haver estalviat 20 punts.",
      achievementpiggy: "Haver estalviat 40 punts.",
      achievementbrokenpiggy: "Haver estalviat 55 punts.",
      achievementpeace: "Haver comprat una mascota",
      achievementmoney: "Haver comprat un negoci.",
      achievementgoldenbars: "Haver comprat 3 negocis",
      achievementone_diamond: "Haver vingut a classe 10 dies",
      achievementtwo_diamonds: "Haver vingut a classe 20 dies",
      achievementthree_diamonds: "Haver vingut a classe 40 dies",
      achievementlots_of_diamonds: "Haver vingut a classe 60 dies",

      achievementchampion: "Haver llegit més de 3 llibres",
      achievementdiamond: "Treure molt bones notes",
      achievementlike: "Ajudar als companys",
      achievementlock: "Ser molt valent",
      achievementUnlocked: "Objectiu assolit!",
    },
  },
  es: {
    translation: {
      //header
      home: "Inicio",
      tools: "Herramientas",
      settings: "Configuración",
      session: "Cerrar sessión",
      access: "Accede",

      //Students/Groups
      students: "Alumnos",
      groups: "Grupos",

      //AddGroups
      groupAddedMsg: "Grupo creado con éxito",
      editGroupMsg: "Grupo editado con éxito",

      //landing
      mainTitle: "Empieza la experiencia ClassBits",
      firstP:
        "Selecciona el idioma que quieres que esté la plataforma. Crea tu clase y ¡empieza a gamificar ahora mismo! Recuerda: cuantos más puntos consigas ¡a más premios podrás optar!",
      createChibi: "Crea la clase",
      setupClass: "Configura la clase",
      quote:
        "Los juegos y la gamificación conducen a altos niveles de compromiso y motivación del alumno. - Karl Kapp -",
      privacyT: "La PRIVACIDAD, uno de nuestros objetivos principales",
      privacyP:
        "Entendemos que los datos de nuestros alumnos son muy sensibles y por esto esta app ha sido específicamente diseñada para garantizar la privacidad.",
      attendanceT: "Diseñada contra el absentismo escolar",
      attendanceP:
        "En colaboración con el Ayuntamiento de Lleida, esta aplicación dispone de un módulo específico contra el absentismo escolar. Ven a clase y obtén diamantes. Con estos diamantes podrás comprar cajas misteriosas que te darán ¡objetos y personajes únicos!",
      explanationT: "App de gestión tutorial configurable",
      explanationP:
        "En ClassBits podrás añadir, editar y borrar alumnos, conductas a destacar, a mejorar e intercambios de puntos. Tu decides qué conductas suman puntos a los Chibits y cuales restan... Incluso puedes escoger como convertir los puntos positivos por premios en la vida real... o dentro del classbits, consiguiendo una Mascota Bits!",
      positiveReinforcementTitle:
        "Fomentamos el refuerzo de conductas positivas y reconducción de conductas negativas",
      positiveReinforcementP:
        "Implementa de forma sencilla y visual las normas de clase y qué se espera de ellos. Gracias al sistema de puntos los alumnos ven de forma fácil y rápida como sus buenas acciones conllevan a buenos resultados. Además ¡todo es modificable!",
      toolsT: "Todas las herramientas que necesitas en un mismo lugar",
      toolsP:
        "Con este kit de herramientas podrás escoger alumnos de forma aleatoria, crear grupos de trabajo de forma automática, medir el ruido del aula, realizar eventos divertidos, intercambiar puntos entre alumnos, frases de reflexión diaria...",
      //avatar.js
      close: "Cerrar",
      custom: "Personalizado",
      save: "Guardar",

      //AddExchange.js
      addExchangeTitle: "Añadir canje",
      exchange: "Canje",
      points: "Puntos",
      addExchangeMsg: "Canje añadido correctamente",
      editExchangeMsg: "Canje añadido correctamente",

      //AddNegative.js
      addNegativeTitle: "Añadir puntos negativos",
      pointsToSubstract: "Puntos a restar",
      addNegativeMsg: "Negativo añadido correctamente",
      editNegativeMsg: "Negativo modificado correctamente",

      //addPet.js
      addPetTo: "Añadir mascota a",
      addPetMsg: "Mascota añadida correctamente",
      subtitleAddPet: "Haz clic en las mascotas para ver su nombre",
      petPoints: "Puntos de mascota",
      petPointsCost: "Precio mascota",
      petTransaction: "Transacción de mascota",
      removePet: "Eliminar mascota",

      //addPositive.js
      addPositiveTitle: "Añadir puntos positivos",
      addPositiveMsg: "Positivo añadido correctamente",
      editPositiveMsg: "Positivo editado correctamente",

      //addStudents.js
      addStudentTitle: "Añadir alumno",
      studentName: "Nombre",
      studentSurname: "Apellido",
      image: "Imagen",
      studentAddedMsg: "Alumno añadido correctamente",
      editStudentMsg: "Alumno editado correctamente",
      addStudentsWindowWarning:
        "Por favor, añade todos los usuarios de la clase sin salir de esta pantalla.",

      //chooseChibit
      createYourChibit: "Crea tu Chibit",
      chooseChibit: "Escoge tu ChiBit",
      subtitleChooseChibit:
        "Darle un vistazo a todos los avatares que hay. Después, cada alumno podrà seleccionar uno. \n Una vez hayas guardado todos los alumnos de tu clase, haz clic en el botón rojo de publicar y ya estará todo listo. \n Para cualquier duda puedes consultar el",
      studentsAmountToBePublished: "Cantidad de alumnos pendientes de publicar",
      publishClass: "Publicar clase",
      studentsAddedMsg: "Clase publicada correctamente",
      studentsToBePublished: "Alumnos añadidos",

      //chronometer
      chronoStart: "Empieza",
      timeDownStart: "Empezar",
      timeDownRemaining: "Tiempo restante",
      hours: "Horas",
      minutes: "Minutos",
      seconds: "Segundos",

      //renderavatars
      addPetPoints: "Añadir puntos de mascota y negocios",
      alertPointsAdded:
        "¡Los propietarios de mascotas y negocios han ganado puntos!",

      //DailySentence.js
      niceSentence: "Genera una frase bonita",

      //Edits
      action: "Acción",
      actions: "Acciones",
      negatives: "A mejorar",
      positives: "A destacar",
      deleteAll: "Borrar todo",

      //Groups
      groupsAmount: "Cantidad de grupos:",
      createGroups: "Crear grupos",
      groupName: "Nombre del grupo",

      //Login
      register: "Regístrate",
      login: "Inicia sesión",
      googleAccess: "Accede con Google",
      haveAccount: "¿Ya tienes cuenta? Inicia sesión",
      notHaveAccount: "¿No tienes cuenta? Regístrate",
      wrongPassword: "La contraseña es incorrecta, vuélvelo a intentar",
      languageTitle: "Selecciona tu idioma",
      fillForm: "Rellena el formulario",
      forgotPassword: "¿Has olvidado tu contraseña? Recupérala",
      resetPassword: "Resetea la contraseña",
      passResetSent: "¡Correo de restablecimiento enviado!",
      contactThroughMail:
        "Al registrarte aceptas que te enviemos correos sobre futuras actualizaciones relevantes de Classbits",

      //Pergamino
      openPergam: "Abrir pergamino",
      pergam: "Pergamino",
      editPergam: "Pergamino editado",
      addedPergam: "Pergamino añadido",
      deletedPergam: "Pergamino borrado",
      addPergam: "Añadir pergamino",
      endedPergams:
        "Todos los eventos han sido mostrados, clica de nuevo para volver a empezar.",
      editPergams: "Editar pergaminos",
      goBack: "Volver atrás",

      //Daily sentence
      addSentence: "Añadir frase",
      sentence: "Frase",
      editSentence: "Frase editada",
      deletedSentence: "Frase borrada",
      addedSentence: "Frase añadida",
      endedSentence:
        "Todas las frases han sido mostrados, clica de nuevo para volver a empezar.",
      editSentences: "Editar frases",

      //Settings
      deleteClassMsg:
        "¿Estás seguro que quieres borrar esta clase? ATENCIÓN: ¡Esta acción es irreversible!",
      classDeletedMsg: "Clase borrada correctamente",
      resetPointsMsg: "Clase reseteada",
      editClassTitle: "Configuración de clase",
      addStudentsBtn: "Añadir alumnos",
      editStudentsBtn: "Editar alumnos",
      pets: "Mascotas",
      pet: "Mascota",
      resetPoints: "Resetear puntos y negocios",
      deleteClass: "Borrar clase",
      editRules: "Configuración de normas",
      addPositiveBtn: "Añadir positivos",
      addNegativeBtn: "Añadir negativos",
      addExchangeBtn: "Añadir canjes",
      editPositiveBtn: "Editar positivos",
      editNegativeBtn: "Editar negativos",
      editExchangeBtn: "Editar canjes",
      addGroupBtn: "Añadir grupo",
      editGroupBtn: "Editar grupos",
      downloadBackup: "Descargar Copia de Seguridad",
      uploadBackup: "Subir Copia de Seguridad",
      backupTitle: "Copia de seguridad",
      successBackupMsg: "Copia de seguridad subida correctamente",
      failedBackupMsg: "Error al subir la copia de seguridad",
      noFileBackupMsg:
        "No se ha seleccionado ningún archivo de copia de seguridad",
      uploadBackupBtn: "Subir backup",
      resetClassMsg:
        "Resetear los puntos de clase y el historial. ATENCIÓN: ¡Esta acción es irreversible!",
      invitedMode: "Modo invitado",
      backToOriginalAccount: "Volver a mi cuenta",

      //Pets.js
      activatePetRewards:
        "Permitir que las mascotas generen 7 puntos semanales",

      //RandomUser
      randomUser: "Usuario aleatorio",

      //soundMeter {t("")}
      totalLoudTime: "Has estado en un ambiente con ruido durante",
      seconds: "segundos",
      giveMicroAccess: "Has de dar acceso al micrófono",
      startSoundMeter: "Empezar a medir",
      stopSoundMeter: "Parar el medidor",
      allowMicroAccess: "Permitir el uso del micrófono",
      soundMeter: "Medidor de ruido",

      //Tools
      dailySentence: "Frase diaria",
      pergamino: "Pergamino del destino",
      groupGenerator: "Generador de grupos",
      randomChibit: "ChiBit aleatorio",
      chrono: "Cronómetro",
      countdown: "Cuenta atrás",
      petsForest: "Bosque de las mascotas",
      exchangePoints: "Intercambio de puntos",
      todoTool: "Tareas de clase",
      seatTool: "Generador asientos aleatorios",

      //exchange points tool
      transferPointsTitle: "Transferencia de puntos",
      senderPoints: "te ha hecho una transferencia de puntos",
      receiverPoints: "Transferencia de puntos a",
      selectSender: "Seleccionar remitente",
      selectReceiver: "Seleccionar destinatario",
      btnTransfer: "Realizar transferencia",
      successfulExchangePointsMsg: "¡Transferencia realizada con éxito!",
      wrongExchangePointsMsg: "Ha habido un problema, vuelve a intentarlo",

      //Footer
      interestLinks: "Enlaces de interés",
      whoAreWe: "Quienes somos",
      contact: "Contacto",
      press: "Prensa",
      footerTextUp:
        "Empieza hoy mismo a gamificar tu clase. Completamente gratis.",
      footerTextDown:
        "Hecho por profesores, para profesores. Con mucho amor 🧡",
      privacyPolicy: "Política de privacidad",
      legalNotice: "Aviso legal",
      copyright: "Todos los derechos reservados",

      //Team Page
      teamH2: "O mejor dicho, quien soy.",
      teamText:
        "Me llamo Cristian Viaplana y soy profesor de educación primaria.\n Desde hace años enseño e imparto, entre otras asignaturas, programación y robótica a niños de primaria. \n En los últimos años, he sido tutor y vi la necesidad de crear una aplicación de gestión tutorial que pudiera dar respuesta a todas las necesidades de mi alumnado. \n Fue en ese momento donde nació ClassBits. \n Realmente, este proyecto ha ido haciendo una metamorfosis dado que a medida que lo usábamos en clase, los niños iban pidiendo nuevas mejoras y funcionalidades. \n A día de hoy, y después de bastantes años de trabajo, ClassBits está disponible para cualquier profesor que quiera usarlo. \n Classbits es el único programa de gestión tutorial creado en España. Además, tiene funcionalidades únicas como algunas de sus herramientas, poder escoger entre más de 110 avatares 3D diferentes, introducción de conceptos de economía básica (con los intercambios, las mascotas que retornan parte de la inversión...) entre otros. \n De un profesor para todos los profesores. Made in Spain 🧡",
      teamH1: "Quienes somos",

      //Contact page
      contactText:
        "¿Hay algún error en la aplicación? \n ¿Crees que deberíamos implementar alguna funcionalidad nueva? \n ¿Quieres contactarme para cualquier tema en general? \n Puedes escribirme a info@classbits.net",

      //Press page
      pressText:
        "¿Quieres mencionarnos en tu medio de comunicación? \n Puedes mostrar/adaptar cualquier apartado de la web para mostrar como funciona ClassBits. Además, aquí tienes recursos para posibles cabeceras de artículos.",
      downloadables: "Descargables",
      downloadablesSub: "Clica en la imagen para descargarla",
      pressRelease: "Notas de prensa",

      //Todo
      writeToDos:
        "Escribe la tarea que quieres realizar (no salgas de la página o se perderán)",

      //emoji gallery
      alertEmojiCopied: "Emoji copiado al portapapeles: ",

      //Account
      myAccount: "Mi cuenta",
      mail: "Correo",
      profile: "Perfil",
      inviteEmailSuccess: "Cuenta invitada con éxito",
      inviteEmailWrong: "Algo no ha funcionado bien, inténtalo más tarde",
      grantedAccess: "Acceso permitido a la clase de {{professorEmail}}",
      rejectedAccess: "Acceso denegado a la clase de {{professorEmail}}",
      titleOtherAccounts: "Accede a otras clases",
      titleInviteOthers: "Invita a otros profesores a esta clase",
      invite: "Invitar",
      saveClassroom: "Guardar clase",
      classroom: "Clase",
      email: "Correo",
      successSavedClassroom: "Clase guardada correctamente",
      errorWarning: "Ha habido un error",
      adminPanel: "Panel de administración",
      accountType: "Tipo de cuenta:",
      copyReferralLink: "Comparte tu enlace de referido",
      linkCopied: "Link copiado correctamente",
      linkCopyError: "Ha habido un error, vuelve a clicar",
      referralExplanation:
        "¡Si la gente se registra con tu enlace, podrás obtener funcionalidades exclusivas dentro de la plataforma!",
      totalRefs: "Total referidos",

      //FooterTools
      orderAbc: "Ordenar alfabéticamente",
      orderOrigin: "Orden inicial",
      multipleSelect: "Selección múltiple",
      takeAttendance: "Pasar lista",
      attendanceRecorded: "Lista pasada",
      havingAttended: "Haber venido a clase",

      //MultiSelectPopup
      selectAll: "Seleccionar a todos",
      disSelectAll: "Deseleccionar a todos",

      //seating generator
      descriptionSeatingGenerator:
        "Haz clic en cualquier lugar de la pantalla para generar asientos aleatorios. Una vez hayas terminado, puedes arrastrarlos para realizar cambios manuales.",

      //Goal-objective planner
      goal_planner: "Objetivos grupales",
      textGoalPlanner:
        "Establece objetivos de clase. Cuando los consigan superar, ganarán los puntos que se hayan pactado.",
      addGoalTitle: "Añadir objectivo de clase",
      goalText: "Objetivo de clase",
      goalNumber: "Cantidad de puntos a ganar",
      addGoal: "Añadir objetivo",
      goalAddedMsg: "¡Objetivo añadido satisfactoriamente!",
      completed: "Conseguido",

      //Records
      record: "Historial",
      earnedPoints: "Puntos ganados",
      lostPoints: "Puntos perdidos",
      all: "Todos",
      description: "Motivo",
      date: "Fecha",

      //newsletter
      newsletterTitle: "¡Únete a nuestra newsletter!",
      newsletterDescription:
        "Entérate de toda las novedades que vamos incorporando en Classbits. Prometemos no hacer spam. Si te suscribes, este recuadro no saldrá nunca más.",
      subscribe: "¡Suscríbete!",

      //thank-you
      thanksForSubscribingTitle: "¡Gracias por Suscribirte!",
      thanksForSubscribingText:
        "¡Tu suscripción ha sido confirmada con éxito! Estamos emocionados de tenerte a bordo.",
      afterSubscribingTitle: "¿Qué Sigue?",
      afterSubscribingText:
        "Prepárate para recibir actualizaciones exclusivas, consejos útiles y las últimas noticias directamente en tu bandeja de entrada.",
      acceptTermsAndConditions: "*Acepto las políticas de privacidad",

      //Student profile
      achievements: "Logros",
      business: "Negocios",
      collectibles: "Coleccionables",
      comingsoon: "Próximamente",
      nextStudent: "Siguiente",
      previousStudent: "Anterior",
      diamonds: "Diamantes",
      yes: "Si",
      no: "No",

      //Lootboxes
      lootboxes: "Cajas misteriosas",
      openLootbox: "Abrir Lootbox",
      lootboxReward: "Premio Lootbox",
      lootboxMsg: "¡Felicidades! Has recibido un",
      legendaryCost: "Coste 15 💎",
      epicCost: "Coste 10 💎",
      commonCost: "Coste 5 💎",
      common: "Común",
      epic: "Épica",
      legendary: "Legendaria",
      notEnoughDiamonds: "No tienes suficientes diamantes",
      openedReward: "¡Felicidades! Has recibido ",
      rewardSkin1: "un personaje legendario: Flora",
      rewardSkin2: "un personaje legendario: Poseidón",
      rewardSkin3: "un personaje legendario: Boreas",
      rewardSkin4: "un personaje legendario: Hefesto",
      rewardSkin5: "un personaje legendario: Zeus",
      rewardPet1: "una mascota única: Camaleón",
      rewardPet2: "una mascota única: Copito de nieve",
      rewardPet3: "una mascota mítica: Quimera",
      rewardPet4: "una mascota mítica: Hydra",
      rewardPet5: "una mascota mítica: Minotauro",
      rewardPoints1: "5 puntos",
      rewardPoints2: "7 puntos",
      rewardPoints3: "10 puntos",
      rewardPoints4: "12 puntos",
      rewardPoints5: "15 puntos",
      businessReward1: "un negocio único: Tienda de electrodomésticos",
      businessReward2: "un negocio único: Tienda de bicis",
      businessReward3: "un negocio único: Lavadero de coches",
      businessReward4: "un negocio único: Autoescuela",
      businessReward5: "un negocio único: Gasolinera",
      businessReward6: "un negocio único: Planta solar",
      businessReward7: "un negocio único: Mercadillo",
      businessReward8: "un negocio único: Fisioterapeuta",
      businessReward9: "un negocio único: Zapatería",
      businessReward10: "un negocio único: Hotel",

      //Business
      supermarket: "Supermercado",
      coffeeshop: "Cafetería",
      bakery: "Panadería",
      fruitstore: "Frutería",
      laundry: "Lavandería",
      carworkshop: "Taller mecánico",
      pharmacy: "Farmacia",
      gym: "Gimnasio",
      beautysalon: "Salón de belleza",
      hardwarestore: "Ferretería",
      clothesstore: "Tienda de ropa",
      bookshop: "Librería",
      hairsalon: "Peluquería",
      restaurant: "Restaurante",
      optics: "Óptica",
      florist: "Floristería",
      factory: "Fábrica",
      lab: "Laboratorio de inventos",
      toyshop: "Juguetería",
      cinema: "Cine",
      appliance: "Electrodomésticos",
      bike_store: "Tienda de bicis",
      carwash: "Lavadero de coches",
      driving_school: "Autoescuela",
      gas_station: "Gasolinera",
      solar_power_plant: "Planta solar",
      marketplace: "Mercadillo",
      massage: "Fisioterapeuta",
      shoe_store: "Zapatería",
      hotel: "Hotel",
      buyBusiness: "compra un negocio",
      subtitleAddBusiness:
        "Haz clic en el negocio para añadirlo en el formulario",
      businessAddedMsg: "Negocio comprado correctamente",
      noBusiness: "No tienes negocios.",
      noBusinessSelected: "No has seleccionado ningún negocio",
      noBusinessToSell: "No puedes vender un negocio que no es tuyo",
      businessRemovedMsg: "Negocio eliminado correctamente",
      removeBusiness: "Vender negocio",
      businessReturnedPointsMsg: "Venta de negocio",
      businessPoints: "Puntos de negocio/s",
      buy: "Comprar",
      sell: "Vender",
      buySell: "Comprar/Vender",
      cost: "Coste",
      return_min: "Retorno mínimo",
      return_max: "Retorno máximo",
      investment: "Inversión",
      businessPurchased: "Compra de negocio",
      propertyOf: "Propietario/a:",
      information: "Información",
      next_payment: "Próximo pago",

      business_description_1:
        "En un supermercado, puedes comprar comida, juguetes y más. Para tener un supermercado, necesitas comprar muchas cosas para venderlas. También necesitas pagar a las personas que trabajan allí. A veces, ganas más dinero cuando mucha gente viene a comprar, como en las fiestas. Pero, en algunas épocas, como cuando las personas están de vacaciones y no compran tanto, el supermercado no gana tanto dinero",
      business_description_2:
        "En una cafetería, hacen bebidas calientes y pasteles. Para tener una cafetería, necesitas comprar café, leche y otras cosas. También necesitas pagar a las personas que hacen el café y los pasteles. A veces, ganas más dinero cuando hace frío y las personas quieren bebidas calientes. Pero, en verano, cuando hace calor, la gente a veces prefiere bebidas frías, por lo que la cafetería no gana tanto dinero.",
      business_description_3:
        "En una panadería, hacen pan y pasteles deliciosos. Para tener una panadería, necesitas comprar ingredientes como harina y azúcar. También necesitas pagar a los panaderos que hacen las delicias. A veces, ganas más dinero cuando las personas quieren pan fresco. Pero, en días lluviosos, las personas tal vez no quieran salir a comprar, y la panadería no gana tanto dinero.",
      business_description_4:
        "En una frutería, venden frutas frescas. Para tener una frutería, necesitas comprar frutas y ponerlas a la vista. También necesitas pagar a las personas que trabajan allí. A veces, ganas más dinero cuando las personas quieren frutas saludables. Pero, en invierno, algunas frutas no están disponibles, y la frutería no gana tanto dinero.",
      business_description_5:
        "En una lavandería, lavan la ropa de las personas. Para tener una lavandería, necesitas comprar lavadoras y detergentes. También necesitas pagar a las personas que lavan la ropa. A veces, ganas más dinero cuando la gente tiene mucha ropa sucia. Pero, en algunos días, como los feriados, las personas no traen tanta ropa, y la lavandería no gana tanto dinero.",
      business_description_6:
        "Un taller de autos arregla coches. Para tener un taller, necesitas herramientas y mecánicos que sepan arreglar coches. A veces, ganas más dinero cuando los coches tienen problemas y la gente viene a que los reparen. Pero, en épocas en las que los coches no se averían tanto, el taller no gana tanto dinero.",
      business_description_7:
        "La farmacia tiene medicinas y cosas para sentirse mejor. Para tener una farmacia, necesitas comprar medicinas y pagar a los farmacéuticos. A veces, ganas más dinero cuando la gente está enferma y necesita medicinas. Pero, cuando las personas están sanas y no necesitan medicinas, la farmacia no gana tanto dinero.",
      business_description_8:
        "En el gimnasio, haces ejercicio para estar sano. Para tener un gimnasio, necesitas comprar máquinas y contratar a entrenadores. A veces, ganas más dinero cuando la gente quiere ponerse en forma, como después de las vacaciones. Pero, en algunos meses, como en verano, las personas pueden preferir hacer ejercicio al aire libre, y el gimnasio no gana tanto dinero.",
      business_description_9:
        "En el salón de belleza, te cortan el pelo y te peinan. Para tener un salón, necesitas comprar herramientas y pagar a las personas que hacen peinados. A veces, ganas más dinero cuando las personas quieren cambiar su look. Pero, en algunos días tranquilos, el salón no gana tanto dinero.",
      business_description_10:
        "En la tienda de herramientas, venden cosas para arreglar y construir. Para tener una tienda, necesitas comprar herramientas y pagar a las personas que trabajan allí. A veces, ganas más dinero cuando las personas tienen proyectos de construcción. Pero, en temporadas tranquilas, la tienda no gana tanto dinero.",
      business_description_11:
        "En la tienda de ropa, venden ropa nueva y bonita. Para tener una tienda, necesitas comprar ropa y pagar a las personas que trabajan allí. A veces, ganas más dinero cuando hay temporadas de moda. Pero, en momentos en que la gente no compra tanta ropa, la tienda no gana tanto dinero.",
      business_description_12:
        "En la librería, venden libros y conocimiento. Para tener una librería, necesitas comprar libros y pagar a las personas que trabajan allí. A veces, ganas más dinero cuando las personas quieren aprender cosas nuevas. Pero, en momentos en que la gente no lee tanto, la librería no gana tanto dinero.",
      business_description_13:
        "En el salón de peluquería, te cortan el pelo y te peinan. Para tener un salón, necesitas comprar herramientas y pagar a las personas que trabajan allí. A veces, ganas más dinero cuando las personas quieren cambiar su look. Pero, en algunos días tranquilos, el salón no gana tanto dinero.",
      business_description_14:
        "En un restaurante, cocinan deliciosa comida para ti. Para tener un restaurante, necesitas ingredientes y cocineros. A veces, ganas más dinero cuando la gente quiere comer fuera, como en vacaciones. Pero, en días en que la gente prefiere cocinar en casa, el restaurante no gana tanto dinero.",
      business_description_15:
        "En la óptica, ayudan a las personas a ver mejor con gafas. Para tener una óptica, necesitas comprar gafas y tener ópticos. A veces, ganas más dinero cuando las personas necesitan gafas nuevas. Pero, cuando las personas no necesitan gafas, la óptica no gana tanto dinero.",
      business_description_16:
        "En la florería, venden flores hermosas. Para tener una florería, necesitas comprar flores y tener floristas. A veces, ganas más dinero cuando las personas quieren regalar flores o decorar sus casas. Pero, en días tranquilos, la florería no gana tanto dinero.",
      business_description_17:
        "En la juguetería, venden juguetes divertidos. Para tener una juguetería, necesitas comprar juguetes y tener empleados. A veces, ganas más dinero cuando los niños quieren jugar con juguetes nuevos. Pero, en momentos en que los niños ya tienen muchos juguetes, la juguetería no gana tanto dinero.",
      business_description_18:
        "En el cine, puedes ver películas emocionantes en la gran pantalla. Necesitas una sala con sillas y una gran pantalla. También proyectores y películas para mostrar. Ganas dinero cuando las personas compran entradas para ver las películas. Algunas películas nuevas pueden atraer a muchos espectadores y hacerte ganar mucho dinero. Pero cuando no hay muchas películas nuevas, el cine puede ganar menos dinero.",
      business_description_19:
        "En una fábrica, fabrican muchas cosas diferentes. Para tener una fábrica, necesitas máquinas y trabajadores. A veces, ganas mucho dinero cuando la fábrica produce muchas cosas y las vende. Pero en épocas en que la gente no compra tanto, la fábrica no gana tanto dinero.",
      business_description_20:
        "En un laboratorio de inventos, las personas crean cosas nuevas e interesantes. Para tener un laboratorio de inventos, necesitas científicos y materiales de investigación. A veces, ganas mucho dinero cuando inventan cosas geniales y las venden. Pero en momentos en que no tienen muchas ideas nuevas, el laboratorio de inventos no gana tanto dinero.",

      //Achievements
      titlebag: "Pequeño ahorrador",
      titlepiggy: "Buen ahorrador",
      titlebrokenpiggy: "Gran ahorrador",
      titlepeace: "Cuidador",
      titlemoney: "Camino al éxito",
      titlegoldenbars: "Inversor tiburón",
      titleone_diamond: "Pequeño asistente",
      titletwo_diamonds: "Buen asistente",
      titlethree_diamonds: "Gran asistente",
      titlelots_of_diamonds: "¡No te pierdes una!",

      titlechampion: "Devora libros",
      titlediamond: "Sacar muy buenas notas",
      titlelike: "Mejor amigo",
      titlelock: "Valentía",

      achievementbag: "Haber ahorrado 20 puntos.",
      achievementpiggy: "Haber ahorrado 40 puntos.",
      achievementbrokenpiggy: "Haber ahorrado 55 puntos.",
      achievementpeace: "Haber comprado una mascota",
      achievementmoney: "Haber comprado un negocio.",
      achievementgoldenbars: "Haber comprado 3 negocios.",
      achievementchampion: "Haber comprado 3 negocios",
      achievementone_diamond: "Haber venido a clase 10 días",
      achievementtwo_diamonds: "Haber venido a clase 20 días",
      achievementthree_diamonds: "Haber venido a clase 40 días",
      achievementlots_of_diamonds: "Haber venido a clase 60 días",

      achievementdiamond: "Sacar muy buenas notas",
      achievementlike: "Ayudar a los compañeros",
      achievementlock: "Ser muy valiente",
      achievementUnlocked: "¡Logro desbloqueado!",
    },
  },
};

const savedLanguage = localStorage.getItem("language") || "es";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: savedLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
