import React from 'react'

function ChristmasTheme() {
      //Christmas theme
  function createSnow() {
    const snow = document.createElement("div");

    snow.innerHTML =
      "<img id='snowflake' src='../../images/christmas/snowflake.webp'>";
    snow.classList.add("snow");

    document.body.appendChild(snow);

    snow.style.left = Math.random() * 100 + "vw";

    snow.style.animationDuration = Math.random() * 5 + 8 + "s";

    setTimeout(() => {
      snow.remove();
    }, 8000);
  }

  setInterval(createSnow, 2000);
  return (
    <div className="halloween">
          <img
            src="../../images/christmas/christmas_gift.png"
            className="christmas_gift"
          />
        </div>
  )
}

export default ChristmasTheme