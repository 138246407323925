import { createSlice } from "@reduxjs/toolkit";

const studentsSlice = createSlice({
  name: "students",
  initialState: [],
  reducers: {
    setStudents: (state, action) => {
      return action.payload;
    },
    addStudents: (state, action) => {
      return action.payload;
    },
    updateStudent: (state, action) => {
      const { id, updatedStudent } = action.payload;
      const index = state.findIndex((student) => student.id == id);
      if (index !== -1) {
        state[index] = updatedStudent;
      }
    },

    addPoints: (state, action) => {
      const student = state.find((student) => student.id == action.payload.id);
      if (student) {
        student.points += action.payload.points;
      }
    },
    subtractPoints: (state, action) => {
      const student = state.find((student) => student.id == action.payload.id);
      if (student) {
        student.points -= action.payload.points;
      }
    },
    updateAllPoints: (state, action) => {
      // map the array, never modify directly the state
      return state.map((student) => ({
        ...student, // copy all students props
        points: student.points + action.payload,
      }));
    },
    resetAllPoints: (state) => {
      Object.keys(state).forEach((studentId) => {
        state[studentId].points = 0;
      });
    },
    addDiamonds: (state, action) => {
      const student = state.find((student) => student.id == action.payload.id);
      if (student) {
        student.diamonds += action.payload.diamonds;
      }
    },
    subtractDiamonds: (state, action) => {
      const student = state.find((student) => student.id == action.payload.id);
      if (student) {
        student.diamonds -= action.payload.diamonds;
      }
    },
    updateAllDiamonds: (state, action) => {
      // map the array, never modify directly the state
      return state.map((student) => ({
        ...student, // copy all students props
        diamonds: student.diamonds + action.payload,
      }));
    },
    resetAllDiamonds: (state) => {
      Object.keys(state).forEach((studentId) => {
        state[studentId].diamonds = 0;
      });
    },
    clearData: (state, action) => {
      return [];
    },
    addStudentAchievements: (state, action) => {
      const { id, achievements } = action.payload;
      const index = state.findIndex((student) => student.id == id);
      if (index !== -1) {
        const student = state[index];
        // Agregar los nuevos logros sin duplicados
        student.achievements = Array.from(
          new Set([...(student.achievements || []), ...achievements])
        );
      }
    },
    setRecords: (state, action) => {
      const recordsToUpdate = action.payload;
      if (state.students && Array.isArray(state.students)) {
        state.students.forEach((student) => {
          if (recordsToUpdate[student.id]) {
            student.records = recordsToUpdate[student.id];
          }
        });
      }
    },
    addRecord: (state, action) => {
      const { studentId, record } = action.payload;
      if (state[studentId]) {
        state[studentId].records = [
          ...(state[studentId].records || []),
          record,
        ];
      }
    },
    resetAllRecords: (state) => {
      Object.keys(state).forEach((studentId) => {
        if (state[studentId].records) {
          state[studentId].records = [];
        }
      });
    },
    resetAllBusinesses: (state) => {
      Object.keys(state).forEach((studentId) => {
        if (state[studentId].business) {
          state[studentId].businesses = [];
        }
      });
    },
    resetAllAchievements: (state) => {
      Object.keys(state).forEach((studentId) => {
        if (state[studentId].achievements) {
          state[studentId].achievements = [];
        }
      });
    },
    resetPet: (state) => {
      Object.keys(state).forEach((studentId) => {
        state[studentId].pet = "";
      });
    },
  },
});

export const {
  setStudents,
  addStudents,
  updateStudent,
  addPoints,
  subtractPoints,
  updateAllPoints,
  resetAllPoints,
  resetAllBusinesses,
  resetPet,
  clearData,
  setRecords,
  addRecord,
  resetAllRecords,
  resetAllAchievements,
  addDiamonds,
  subtractDiamonds,
  updateAllDiamonds,
  resetAllDiamonds,
  addStudentAchievements,
} = studentsSlice.actions;

export default studentsSlice.reducer;
