import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { Link } from "react-router-dom";
import firebaseApp from "../credentials";
import { getAuth, signOut, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import useRequireAuth from "../hooks/useRequireAuth";

import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import { login } from "../features/auth/authSlice";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editableToast,
  wrongToast,
  correctToast,
} from "../Components/ToastUtils";

import { clearData } from "../features/students/studentsSlice";
import { addEmail } from "../features/invites/inviteSlice";
import {
  deleteInviteThunk,
  deleteSavedClassroomThunk,
  addSavedClassroomThunk,
} from "../features/invites/invitesThunk";
import { setOriginalEmail } from "../features/auth/authSlice";

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export default function Profile() {
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  useRequireAuth();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const originalEmail = useSelector((state) => state.auth.originalEmail);
  const accountType = useSelector((state) => state.settings.accountType);
  const referralLink = `https://classbits.net/register?ref=${btoa(userEmail)}`;
  const referredCount = useSelector(
    (state) => state.referrals.referredCount || 0
  );

  const currentEmails = useSelector((state) => state.invites.emails);
  const savedClassrooms = useSelector((state) => state.invites.savedClassrooms);

  const [isAnimated, setIsAnimated] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);

  const { t, i18n } = useTranslation();

  const copyReferralLink = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        correctToast(t("linkCopied"));
      })
      .catch((err) => {
        wrongToast(t("linkCopyError"));
      });
  };

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/");
    navigate(0);
  }

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(logout());
        updateReRender();
      })
      .catch((error) => {
        console.error("Error al cerrar la sesión:", error);
      });
  };

  async function inviteEmail(e) {
    e.preventDefault();
    const newEmail = e.target.newEmail.value;
    const userDocRef = doc(db, "usuarios", userEmail);

    try {
      await updateDoc(userDocRef, {
        emails: arrayUnion(newEmail),
      });
      correctToast(t("inviteEmailSuccess"));
      dispatch(addEmail(newEmail));
    } catch (error) {
      wrongToast(t("inviteEmailWrong") + error);
    }
  }

  async function saveClassroom(e) {
    e.preventDefault();

    const formElement = document.getElementById("saveClassroomForm");
    const formData = new FormData(formElement);
    const classroomName = formData.get("classroomName");
    const professorEmail = formData.get("professorEmail");
    let lastId = savedClassrooms.slice(-1)[0]?.id || 0;

    const userDocRef = doc(db, "usuarios", userEmail);

    const newSavedClassroom = {
      id: lastId + 1,
      name: classroomName,
      email: professorEmail,
    };

    try {
      await updateDoc(userDocRef, {
        savedClassrooms: arrayUnion(newSavedClassroom),
      });
      correctToast(t("successSavedClassroom"));
      //update redux & db
      dispatch(addSavedClassroomThunk(userEmail, newSavedClassroom));
      formElement.reset();
    } catch (error) {
      wrongToast(t("errorWarning") + error);
    }
  }

  //reset password
  async function handleResetPassword(e) {
    e.preventDefault();
    const email = e.target.resetEmail.value;

    try {
      await sendPasswordResetEmail(auth, email);
      setShowErrorMessage("");
      setShowSuccessMessage(true);
      setShowResetPasswordForm(false);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setShowErrorMessage(errorMessage);
    }
  }

  async function backToOriginalAccount(professorEmail) {
    const userDocRef = doc(db, "usuarios", professorEmail);

    try {
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const professorData = docSnap.data();
        if (professorData) {
          dispatch(clearData());
          dispatch(logout());
          dispatch(setOriginalEmail(null));
          dispatch(login(professorEmail));
          correctToast(
            i18n.t("grantedAccess", { professorEmail: professorEmail })
          );
        } else {
          wrongToast(
            i18n.t("rejectedAccess", { professorEmail: professorEmail })
          );
        }
      } else {
        // El documento del profesor no existe
        wrongToast(
          i18n.t("rejectedAccess", { professorEmail: professorEmail })
        );
      }
    } catch (error) {
      wrongToast(i18n.t("rejectedAccess", { professorEmail: professorEmail }));
    }
  }

  async function accessClass(professorEmail) {
    const userDocRef = doc(db, "usuarios", professorEmail);

    try {
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const professorData = docSnap.data();
        if (professorData.emails && professorData.emails.includes(userEmail)) {
          dispatch(clearData());
          dispatch(logout());
          dispatch(setOriginalEmail(userEmail));
          dispatch(login(professorEmail));
          correctToast(
            i18n.t("grantedAccess", { professorEmail: professorEmail })
          );
        } else {
          wrongToast(
            i18n.t("rejectedAccess", { professorEmail: professorEmail })
          );
        }
      } else {
        // El documento del profesor no existe
        wrongToast(
          i18n.t("rejectedAccess", { professorEmail: professorEmail })
        );
      }
    } catch (error) {
      wrongToast(i18n.t("rejectedAccess", { professorEmail: professorEmail }));
    }
  }

  const handleDeleteEmail = (emailToRemove) => {
    dispatch(deleteInviteThunk(emailToRemove, currentEmails));
  };

  const handleDeleteSavedClassroom = (classroomId) => {
    dispatch(
      deleteSavedClassroomThunk(userEmail, classroomId, savedClassrooms)
    );
  };

  //Progress bar
  function updateProgressBars() {
    const progressPercentage = Math.min((referredCount / 3) * 100, 100);

    setProgressWidth(progressPercentage);
  }

  useEffect(() => {
    if (!isAnimated) {
      setTimeout(() => {
        setIsAnimated(true);
        updateProgressBars();
      }, 500);
    }
  }, [isAnimated, referredCount]);

  return (
    <>
      <Header />
      {originalEmail ? (
        <>
          <div className="login-page">
            <h2>{t("invitedMode")}</h2>
            <span>
              <strong>{t("mail")}</strong> - {originalEmail}
            </span>
            <p>
              <button
                className="btn btn-success"
                onClick={() => backToOriginalAccount(originalEmail)}
              >
                {t("backToOriginalAccount")}
              </button>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="login-page">
            <h2>{t("myAccount")}</h2>
            <span>
              <strong>{t("mail")}</strong> - {userEmail}
            </span>
            {showErrorMessage ? (
              <p className="errorWarning">{showErrorMessage}</p>
            ) : (
              ""
            )}
            {showSuccessMessage && (
              <p className="successMessage">{t("passResetSent")}</p>
            )}

            {showResetPasswordForm ? (
              ""
            ) : (
              <button
                className="button-warning"
                style={{ width: 300, marginTop: 10, padding: 4 }}
                onClick={() => setShowResetPasswordForm(true)}
              >
                {t("resetPassword")}
              </button>
            )}
            {showResetPasswordForm && (
              <form
                className="reset-password-form"
                onSubmit={handleResetPassword}
              >
                <input type="text" placeholder="email" id="resetEmail" />
                <button className="button-success">{t("resetPassword")}</button>
              </form>
            )}
            <p>
              <button
                className="button-error"
                style={{ width: 300, padding: 4 }}
                onClick={() => handleSignOut()}
              >
                {t("session")}
              </button>
            </p>
            <p>
              <button
                className="button-success"
                style={{ width: 300, padding: 4 }}
                onClick={copyReferralLink}
              >
                {t("copyReferralLink")}
              </button>
            </p>
            <h3>
              {t("totalRefs")}: {referredCount ? referredCount : "0"}
            </h3>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `${progressWidth}%` }}
              >
                {progressWidth}%
              </div>
            </div>
            <p>{t("referralExplanation")}</p>
            {accountType == "admin" ? (
              <p>
                <Link to="/admin-panel" className="footer-button">
                  {t("adminPanel")}
                </Link>
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="column">
              <div className="login-page">
                <h2 className="subtitle">{t("titleOtherAccounts")}</h2>
                <form onSubmit={saveClassroom} id="saveClassroomForm">
                  <input
                    type="text"
                    placeholder={t("classroom")}
                    id="classroomName"
                    name="classroomName"
                    required
                  />
                  <input
                    type="text"
                    placeholder={t("email")}
                    id="professorEmail"
                    name="professorEmail"
                    required
                  />
                  <div className="center">
                    <button className="btn-success" type="submit">
                      {t("saveClassroom")}
                    </button>
                  </div>
                </form>
                <p></p>
              </div>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>{t("classroom")}</th>
                    <th style={{ textAlign: "center" }}>{t("email")}</th>
                    <th style={{ textAlign: "center" }}>{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(savedClassrooms)
                    ? savedClassrooms.map((classroom, index) => {
                        return (
                          <tr key={classroom.id}>
                            <td>{classroom.name}</td>
                            <td>{classroom.email}</td>
                            <td>
                              <button
                                className="btn-x btn-success"
                                onClick={() => accessClass(classroom.email)}
                              >
                                {t("access")}
                              </button>
                              <button
                                className="btn-x btn-delete"
                                onClick={() =>
                                  handleDeleteSavedClassroom(classroom.id)
                                }
                              >
                                X
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="column">
              <div className="login-page">
                <h2 className="subtitle">{t("titleInviteOthers")}</h2>
                <form onSubmit={inviteEmail}>
                  <input type="text" placeholder={t("email")} id="newEmail" />
                  <div className="center">
                    <button className="btn-success" type="submit">
                      {t("invite")}
                    </button>
                  </div>
                </form>
              </div>
              <p></p>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>No.</th>
                    <th style={{ textAlign: "center" }}>{t("email")}</th>
                    <th style={{ textAlign: "center" }}>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentEmails)
                    ? currentEmails.map((email, index) => {
                        return (
                          <tr key={email.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{email}</td>
                            <td>
                              <button
                                className="btn-x btn-delete"
                                onClick={() => handleDeleteEmail(email)}
                              >
                                X
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}
