import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import "./EditClassroom.css";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { updateStudentPoints } from "../features/students/studentsThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast, wrongToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

const ExchangePoints = () => {
  const { t } = useTranslation();
  let audioExchange = new Audio("./audio/sent.mp3");

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [points, setPoints] = useState("");

  const exchangeSound = () => {
    audioExchange.play();
  };

  const resetForm = () => {
    setSenderId("");
    setReceiverId("");
    setPoints("");
  };

  const exchangePoints = async () => {
    const parsedSenderId = parseInt(senderId);
    const parsedReceiverId = parseInt(receiverId);

    const rawPoints = points.trim();
    if (!rawPoints.match(/^\d+$/)) {
      console.error("Valor de puntos no válido.");
      return;
    }
    const parsedPoints = parseInt(rawPoints);

    const senderStudentIndex = students.findIndex(
      (student) => student.id === parsedSenderId
    );
    const receiverStudentIndex = students.findIndex(
      (student) => student.id === parsedReceiverId
    );

    if (senderStudentIndex !== -1 && receiverStudentIndex !== -1) {
      dispatch(
        updateStudentPoints(
          userEmail,
          parsedSenderId,
          -parsedPoints,
          `${t("receiverPoints")} ${students[receiverStudentIndex].name}`
        )
      );
      dispatch(
        updateStudentPoints(
          userEmail,
          parsedReceiverId,
          parsedPoints,
          `${students[senderStudentIndex].name} ${t("senderPoints")}`
        )
      );

      exchangeSound();
      correctToast(t("successfulExchangePointsMsg"));
      resetForm();
    } else {
      wrongToast(t("wrongExchangePointsMsg"));
    }
  };

  return (
    <>
      <Header />
      <div className="editTitle">
        <h2>{t("transferPointsTitle")}</h2>
        <div>
          <select
            id="sender"
            value={senderId}
            onChange={(e) => setSenderId(e.target.value)}
          >
            <option value="">{t("selectSender")}</option>
            {students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.name} {student.surname}
              </option>
            ))}
          </select>

          <input
            type="number"
            min="0"
            id="points"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />

          <select
            id="receiver"
            value={receiverId}
            onChange={(e) => setReceiverId(e.target.value)}
          >
            <option value="">{t("selectReceiver")}</option>
            {students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.name} {student.surname}
              </option>
            ))}
          </select>
          <button className="btn btn-edit" onClick={exchangePoints}>
            {t("btnTransfer")}
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ExchangePoints;
