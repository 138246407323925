import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  referredCount: 0,
};

// Creamos el slice
const referralsSlice = createSlice({
  name: "referrals",
  initialState,
  reducers: {
    setRefs: (state, action) => {
      state.referredCount = action.payload;
    },
  },
});

export const { setRefs } = referralsSlice.actions;

export default referralsSlice.reducer;
